<template>
  <el-upload
          action="#"
          list-type="picture-card"
          :auto-upload="false">
    <i slot="default"  class="el-icon-plus"></i>
    <div slot="file" slot-scope="{file}">
      <img
              class="el-upload-list__item-thumbnail"
              :src="file.url" alt=""
      >
      <span class="el-upload-list__item-actions">
        <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleDownload(file)"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
    </div>

  </el-upload>
</template>
<script>
  // Charts
  export default {
    name: 'uploader',

    props: ['type', 'id', 'name', 'multiple'],
    data() {
      return {
        fileList: [],
      }
    },
    methods: {
      addItem () {
        this.$router.push({ path: `/project/asdas/add-item`})
      },  
      createDocument (file, a, b) {
      },
      upload (context) {
        return this.$store.dispatch('uploadDocument', { type: this.type, id: this.docId, files: [context.file] })
      }
    },
    mounted() {

    }
  };
</script>
<style></style>
