<template>
  <div>
    <base-header>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right"></div>
      </div>
    </base-header>
    <div class="container-fluid">
      <card no-body>
        <template slot="header">
          <div class="row">
            <div class="col-3">
              <h3 class="mb-0">{{ $t('global.data') }}</h3>
              {{ $t('global.total_weight') }}: {{ totalWeight }} kg
            </div>
            <div class="col-3">
              <div>Gesamte Montagezeit (in h): {{ calculatedManpower() }}</div>
              <div>ABGELEHNTE Gesamte Montagezeit (in h): {{ calculatedManpower(true) }}</div>
            </div>
            <div class="col-6 text-right">
              <!-- <base-button size="sm" type="primary" @click="showPriceList = true">{{ $t('global.price_list') }}</base-button> -->
              <!-- <price-list :show.sync="showPriceList"></price-list> -->
              <base-button type="primary" size="sm" @click="addItem" :disabled="project.state === 'ARCHIVED'">{{$t('global.new')}}</base-button>
            </div>
          </div>
        </template>

        <template slot="footer">
          <div class="row">
            <div class="col-12 text-right">
              <base-button type="primary" size="sm" @click="addItem" :disabled="project.state === 'ARCHIVED'">{{$t('global.new')}}</base-button>
            </div>
          </div>
        </template>

        <div class="card-footer py-4 d-flex justify-content-between align-items-center">
          <el-pagination
            background
            :current-page="currentPage"
            @current-change="onCurrentChange"
            :total="projectAddressPositions.length"
            :page-count="pageCount"
            :page-size="pageSize"
            layout="prev, pager, next">
          </el-pagination>
          <el-select
            class="select-primary mr-2"
            v-model="pageSize" filterable>
              <el-option class="select-primary ml-1"
                value="10"
                label="10"
                key="10">
              </el-option>
              <el-option class="select-primary ml-1"
                value="50"
                label="50"
                key="50">
              </el-option>
              <el-option class="select-primary ml-1"
                :value="listLength"
                :label="$t('message.page_size_all', { allCount: listLength })"
                key="100">
              </el-option>
            </el-select>
        </div>
        
        <el-table
          ref="inputTable"
          class="table-responsive table-flush position-table"
          :row-class-name="tableRowClassName"
          header-row-class-name="thead-light"
          :data="paginatedItems"
          row-key="id"
          stripe
          style="width: 100%;"
        >
          <el-table-column :label="$t('global.operations')" width="130" fixed="left">
            <template v-slot="{row}">
              <el-tooltip :content="$t('global.edit')" placement="top">
                <base-button type="link" size="narrow" @click="edit(row)" :disabled="project.state === 'ARCHIVED'">
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit text-black"></i>
                  </span>
                </base-button>
              </el-tooltip>
              <el-tooltip :content="$t('global.copy')" placement="top">
                <base-button type="link" size="narrow" @click="copy(row)" :disabled="project.state === 'ARCHIVED'">
                  <span class="btn-inner--icon">
                    <i class="fas fa-copy text-black"></i>
                  </span>
                </base-button>
              </el-tooltip>
              <el-tooltip :content="$t('global.delete')" placement="top">
                <base-button type="link" size="narrow" @click="askDelete(row)" :disabled="project.state === 'ARCHIVED'">
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash text-black"></i>
                  </span>
                </base-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column  prop="key" label="ID" width="120"></el-table-column>
          <el-table-column  label="Sort" width="120">
            <template v-slot="{row}">
              <input type="text" class="form-control" @change="event => onSortChanged(event, row)" :value="row.sort"/>
            </template>
          </el-table-column>

          <el-table-column prop="order_no" :label="$t('global.order')" width="160"></el-table-column>
          <el-table-column
            prop="address.address.name"
            :label="$t('message.einsatzort')" width="140"
            ></el-table-column>
          <el-table-column prop="pt_number" :label="$t('position.pt_number')" width="150">
            <template v-slot="{row}">
              {{row.pt_number}}
            </template>
          </el-table-column>
          <el-table-column :label="$t('global.category')" width="250">
            <template v-slot="{row}">
              <div v-for="mat in row.materials" v-if="mat.category">{{mat | category(currentLocale)}}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('message.area')" width="150">
              <template v-slot="{row}">
              {{row.area  ? row.area.name : ''}}
            </template>
          </el-table-column>
          <el-table-column prop="bhb_position.name" :label="$t('position.im_position')" width="200"></el-table-column>
          <el-table-column :label="$t('position.material')" width="250">
            <template v-slot="{row}">
              {{ row | firstmaterial('MAT', currentLocale) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('position.construction')" width="250">
            <template v-slot="{row}">
              {{ row | firstmaterial('KON', currentLocale) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('position.quantity')" width="120">
            <template v-slot="{row}">
              <div class="disabled-hidden" v-for="mat in row.materials" v-if="mat.quantity !== '0.00'">{{mat.quantity}}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('position.unit')" width="120">
            <template v-slot="{row}">
              <div class="disabled-hidden" v-for="mat in row.materials" v-if="mat.article">{{mat.article.Base_Unit_of_Measure}}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('position.width')" width="120">
            <template v-slot="{row}">
              <div class="disabled-hidden" v-for="mat in row.materials">{{mat.width | number}}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('position.height')" width="120">
            <template v-slot="{row}">
              <div  class="disabled-hidden" v-for="mat in row.materials">{{mat.height | number}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="assembly_info.name" :label="$t('position.assembly')" width="120"></el-table-column>
          <el-table-column :label="$t('message.weight')" width="120">
            <template v-slot="{row}">
              <div class="disabled-hidden">
                {{row.materials ? row.materials.reduce((val, mat) => val + parseFloat(mat.weight || 0), 0) : 0 | number}}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="card-footer py-4 d-flex justify-content-between align-items-center">
          <el-pagination
            background
            :current-page="currentPage"
            @current-change="onCurrentChange"
            :total="projectAddressPositions.length"
            :page-count="pageCount"
            :page-size="pageSize"
            layout="prev, pager, next">
          </el-pagination>
          <el-select
            class="select-primary mr-2"
            v-model="pageSize" filterable>
              <el-option class="select-primary ml-1"
                value="10"
                label="10"
                key="10">
              </el-option>
              <el-option class="select-primary ml-1"
                value="50"
                label="50"
                key="50">
              </el-option>
              <el-option class="select-primary ml-1"
                :value="listLength"
                :label="$t('message.page_size_all', { allCount: listLength })"
                key="100">
              </el-option>
            </el-select>
        </div>

      </card>
    </div>

    <modal :show.sync="modals.deletePosition">
      <h6 slot="header" class="modal-title">{{ $t('position.dialog.delete.title') }}</h6>
      <p>{{ $t('position.dialog.delete.message') }}</p>
      <template slot="footer">
        <base-button type="primary" @click="deletePosition" :loading="loading">{{ $t('global.yes') }}</base-button>
        <base-button type="link" class="ml-auto" @click="modals.deletePosition = false">{{ $t('global.cancel') }}</base-button>
      </template>
    </modal>

  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { Sortable } from "sortablejs";
import PriceList from "./PriceList";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "DataInput",
  components: {
    PriceList,
    InfiniteLoading,
  },
  computed: {
    ...mapGetters([
      "project",
      "projectAddressPositions",
      "positions",
      "currentProjectAddress",
      "currentLocale",
    ]),
    totalWeight: function () {
      let result = 0;
      if (this.projectAddressPositions) {
        this.projectAddressPositions.forEach((pos) =>
          pos.materials
            ? pos.materials.forEach(
                (mat) => (result += mat.weight ? parseFloat(mat.weight) : 0)
              )
            : 0
        );
      }
      return result;
    },
    listLength() {
      if (this.pageSize === this.projectAddressPositions.length - 1) {
        this.$store.commit(
          "SET_DATA_INPUT_PAGE_SIZE",
          this.projectAddressPositions.length
        );
      }
      return this.projectAddressPositions.length;
    },
    pageCount() {
      return Math.ceil(this.projectAddressPositions.length / this.pageSize);
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.projectAddressPositions.slice(start, end);
    },
    currentPage: {
      get() {
        return this.$store.getters.dataInputCurrentPage;
      },
      set(val) {
        this.$store.commit("SET_DATA_INPUT_CURRENT_PAGE", val);
      },
    },
    pageSize: {
      get() {
        return this.$store.getters.dataInputPageSize;
      },
      set(val) {
        this.$store.commit("SET_DATA_INPUT_PAGE_SIZE", val);
      },
    },
  },
  watch: {
    projectAddressPositions() {
      this.items = this.items.concat(
        this.projectAddressPositions.slice(0, this.size * this.page)
      );
    },
  },
  data() {
    return {
      enableSorting: true,
      items: [],
      startIndex: 0,
      size: 25,
      showPriceList: false,
      modals: {
        deletePosition: false,
      },
      posToDelete: null,
      loading: false,
      hasMotherProject: false
    };
  },
  methods: {
    ...mapActions(["updatePositionSort", "loadPositionsForPorject"]),
    edit(row) {
      this.$router.push({
        path: `/project/${this.project.id}/data/${row.id}`,
      });
    },
    async copy(row) {
      return Vue.http
        .get(`position/${row.id}/copy`, {params: {createParent: this.hasMotherProject}})
        .then(async (res) => {
          const id = res.body.id;
          await this.loadPositionsForPorject(this.project.id);
          this.$router.push({
            path: `/project/${this.project.id}/data/${id}`,
          });
        })
        .catch((error) => {
          this.$notify.error({
            title: "Error",
            message: error.body.message,
          });
        });
    },
    addItem() {
      this.$router.push({ path: `/project/${this.project.id}/data/add` });
    },
    copySelection() {},
    deleteSelection() {},
    askDelete(pos) {
      this.posToDelete = pos;
      this.modals.deletePosition = true;
    },
    async deletePosition() {
      try {
        this.loading = true;
        if (this.posToDelete.materials) {
          const ids = this.posToDelete.materials.map((mat) => mat.id);
          await this.$store.dispatch("deleteMaterial", { id: ids.join(",") })
            .then(() => {
                if (this.hasMotherProject) {
                  Vue.http.delete('material/projectsync/' + this.posToDelete.materials.map(m => m.id).join(','), {});
                }
            });
        }
        await this.$store.dispatch("deletePosition", {id: this.posToDelete.id})
            .then(() => {
                if (this.hasMotherProject) {
                  Vue.http.delete('position/projectsync/' + this.posToDelete.id, {});
                }
            });
        await this.loadPositionsForPorject(this.$route.params.id);
        this.loading = false;
        this.modals.deletePosition = false;
      } catch (error) {
        this.modals.deletePosition = false;
        this.loading = false;
        this.$notify({
          verticalAlign: "top",
          horizontalAlign: "right",
          message: this.$t("message.delete_error"),
          type: "error",
        });
      }
    },
    tableRowClassName({ row }) {
      if (row.state_bhb === "ABGELEHNT") {
        return "disabled-row no-hover";
      }
      return "no-hover";
    },
    infiniteHandler($state) {
      if (this.projectAddressPositions.length > 0) {
        const newItems = this.projectAddressPositions.slice(
          this.startIndex,
          this.size * this.page
        );
        if (newItems.length > 0) {
          this.items = this.items.concat(newItems);
          this.startIndex = this.size * this.page;
          this.page += 1;

          $state.loaded();
        } else {
          $state.complete();
        }
      }
      $state.reset();
    },
    onCurrentChange(pageNumber) {
      this.currentPage = pageNumber;
    },
    onSortChanged(event, row) {
      const value = Number(event.target.value);
      row.sort = value;
      this.$store.dispatch("updatePosition", {
        id: row.id,
        data: { sort: value },
      });
    },
    calculatedManpower(declined = false) {
      let sum = 0;
      if (!declined) {
        this.projectAddressPositions.forEach(pos => {
          if (pos.manpower) {
            let mp = pos.manpower.replace(',', '.');
            sum += parseFloat(mp);
          }
        });
      } else {
        this.projectAddressPositions.forEach(pos => {
          if (pos.manpower && pos.state_bhb === 'ABGELEHNT') {
            let mp = pos.manpower.replace(',', '.');
            sum += parseFloat(mp);
          }
        });
      }

      return sum;
    },
    async getMotherProject() {
      let projectMatches = await this.$store.dispatch('loadProjectMappingByChild', {
          type: 'project', 
          child_id: this.$route.params.id
        })

      this.hasMotherProject = projectMatches.length ? true : false;
    }
  },
  mounted() {
    this.pageSize = 50;
    const table = document.querySelector(".position-table tbody");
    this.getMotherProject();
    //const self = this;

    // Sortable.create(table, {
    //   handle: ".sortable-handle",
    //   animation: 150,
    //   ghostClass: "red-highlight",
    //   onEnd({ newIndex, oldIndex }) {
    //     const targetRow = self.projectAddressPositions.splice(oldIndex, 1)[0];
    //     self.projectAddressPositions.splice(newIndex, 0, targetRow);
    //     self.updatePositionSort();
    //   }
    // });
  },
};
</script>
<style>
.btn-narrow {
  font-size: 0.75rem;
  margin-right: 0.1rem;
  padding: 0px;
}

.disabled-row {
  opacity: 0.3;
  filter: grayscale(100%);
}

.disabled-row .disabled-hidden {
  opacity: 0;
}
</style>
