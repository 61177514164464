<template>
  <SlideYUpTransition :duration="animationDuration">
    <div id="myNav" class="overlay" v-if="show">
      <!-- Button to close the overlay navigation -->
      <a @click="closeModal" class="closebtn" onclick="closeNav()" v-if="showCloseButton">&times;</a>

      <!-- Overlay content -->
      <div class="overlay-content">
       <slot />
      </div>
    </div>
  </SlideYUpTransition>
</template>
<script>
  import { SlideYUpTransition } from 'vue2-transitions'

  export default {
    name: "overlay",
    components: {
      SlideYUpTransition
    },
    props: {
      show: Boolean,
      showClose: {
        type: Boolean,
        default: true
      },
      animationDuration: {
        type: Number,
        default: 500,
        description: "Modal transition duration"
      }
    },
    methods: {
      closeModal() {
        this.$emit("update:show", false);
        this.$emit("close");
      }
    },
    watch: {
      show(val) {
        // let documentClasses = document.body.classList;
        // if (val) {
        //   documentClasses.add("modal-open");
        // } else {
        //   documentClasses.remove("modal-open");
        // }
      }
    },
    mounted () {
    }
  };
</script>
<style>

</style>
