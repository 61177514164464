<template>
  <div class="border-top mt-2 pt-2">
    <div class="row">
      <div class="col-md-2">
        <base-input :label="title" :validator="validator.mat_no">
          <el-tooltip placement="right" :content="
            material.variant
              ? currentLocale === 'de'
                ? material.variant.InternalDescription
                : material.variant.Text_en
              : ''
          " :disabled="material.variant === null">
            <el-select :value="
              (material.mat_no || '-') + '/' + (material.VariantCode || '-')
            " @change="variantSelected" filterable remote :disabled="pos >= 1"
              :placeholder="$t('global.input_select_placeholder')" :remote-method="findVariants">
              <el-option v-for="variant in variants" :key="variant.id" :label="
                variant.ItemNo +
                '/' +
                variant.Code +
                ' ' +
                (currentLocale === 'de'
                  ? variant.InternalDescription
                  : variant.Text_en)
              " :value="variant.id"></el-option>
            </el-select>
          </el-tooltip>
          <span v-if="(material.variant && title == 'Material')" class="small">{{ material.variant.ConfectionType }}</span>
          <span v-if="(material.variant && title != 'Material')" class="small">{{ material.variant.LogisticType }}</span>
        </base-input>
      </div>
      <div class="col-md-2">
        <base-input :label="$t('data_input.category') + '*'" :validator="validator.category">
          <el-select v-model="material.category_id" filterable :placeholder="$t('global.input_select_placeholder')"
            @change="categoryChanged($event)">
            <el-option v-for="option in categories" :key="option.id" :label="
              currentLocale !== 'en'
                ? option.name
                : option.name_en
            " :value="option.id">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-md-2">
        <base-input :label="$t('data_input.motive')" :validator="validator.motiv" v-model="material.motiv">
        </base-input>
      </div>
      <div class="col-md-2">
        <base-input type="number" :label="$t('data_input.material_manager.perimeter_placeholder')" placeholder="m"
          disabled v-model="extent" />
      </div>
      <div class="col-md-2">
        <base-input type="number" :label="$t('data_input.material_manager.area_size_placeholder')" placeholder="qm"
          disabled v-model="areaqm" />
      </div>
      <div class="col-md-2">
        <span v-if="material.type === 'MAT'">
          <el-checkbox :label="$t('data_input.material_manager.purchase')"
            :checked="material.purchase == 1 ? true : false" v-model="material.purchase"></el-checkbox>

          <el-select v-model="material.unit" :placeholder="$t('data_input.material_manager.unit')">
            <el-option value="Stück" :label="$t('data_input.material_manager.piece')" />
            <el-option value="Pschl." :label="$t('data_input.material_manager.fixed')" />
            <el-option value="qm" :label="$t('data_input.material_manager.qm')" />
          </el-select>
        </span>
      </div>

      <div class="col-md-2">
        <base-input type="number" :label="$t('data_input.material_manager.quantity_label')" placeholder="Stück"
          v-model="material.quantity" :validator="validator.quantity" />
      </div>
      <div class="col-md-2">
        <base-input type="number" :label="$t('data_input.material_manager.width_label')" placeholder="mm"
          v-model="material.width" :validator="validator.width" />
      </div>
      <div class="col-md-2">
        <base-input type="number" :label="$t('data_input.material_manager.height_label')" placeholder="mm"
          v-model="material.height" :validator="validator.height" />
      </div>
      <div class="col-md-2">
        <base-input type="number" :label="
          isPerQm
            ? this.$t('data_input.material_manager.qm_price_label')
            : this.$t('data_input.material_manager.m_price_label')
        " v-if="isPerQm || isPerExtent" v-model="material.price" :validator="validator.price"
          @change="priceChanged" />
      </div>
      <div class="col-md-2">
        <base-input type="number" :label="$t('data_input.material_manager.unit_price_label')" v-if="!isPercent"
          v-model="material.price_unit" @change="priceUnitChanged" :disabled="isInfoArticle" />
        <base-input type="number" :label="$t('data_input.material_manager.price_percent_label')" v-if="isPercent"
          v-model="material.price_percent" @change="priceUnitChanged" :disabled="isInfoArticle" />
      </div>
      <div class="col-md-2">
        <base-input type="number" :label="$t('data_input.material_manager.total_price_label')" :placeholder="
          $t('data_input.material_manager.total_price_placeholder')
        " disabled v-model="material.price_total" />
      </div>
    </div>
    <div class="d-flex">
      <div class="ml-auto">
        <base-button size="sm" type="primary" @click="$emit('delete', material)">
          {{ $t("message.delete-mat", { title }) }}
        </base-button>
        <base-button size="sm" type="primary" @click="reset()">
          {{ $t("message.reset-mat", { title }) }}
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import Vue from "vue";

export default {
  name: "MaterialEditor",
  props: {
    title: String,
    material: Object,
    pos: Number,
    validator: Object,
    validCats: Array
  },
  computed: {
    ...mapGetters([
      "project",
      "position",
      "rides",
      "globalOptions",
      "currentLocale",
    ]),
    isPerQm() {
      if (this.material.article == null) return false;
      if (this.material.article.Base_Unit_of_Measure == null) return false;
      return this.material.article.Base_Unit_of_Measure.toLowerCase() == "qm";
    },
    isPerExtent() {
      if (this.material.article == null) return false;
      if (this.material.article.Base_Unit_of_Measure == null) return false;
      return this.material.article.Base_Unit_of_Measure.toLowerCase() == "lfdm";
    },
    isPercent() {
      if (this.material.variant == null) return false;
      return this.material.variant.price_percent;
    },
    categories() {
      //WARNING: MAGIC STRING !
      return this.globalOptions.filter((o) => o.type == "ARTKAT" && (o.name !== 'Werkzeuge' && o.name !== 'Werkzeug') && this.validCats.includes(o.name));
    },
  },
  data() {
    return {
      areaqm: 0,
      extent: 0,
      price: 0,
      variants: [],
      validCat: [],
      firstVariantChange: false,
      isInfoArticle: false
    };
  },
  watch: {
    // 'material.price_unit': function (val) {
    //   //this.$set(this.material, 'price', val)
    //   this.material.price_total = this.round(this.material.price_unit * this.material.quantity)
    //   this.$emit('change')
    // },
    // 'material.price': function (val) {
    //   // this.$set(this.material, 'price', val)
    //   this.$set(this.material, 'price_unit', this.getUnitPrice())
    //   this.$emit('change')
    // },
    "material.width": function () {
      this.geoChanged();
    },
    "material.height": function () {
      this.geoChanged();
    },
    "material.mat_no": function () {
      this.matChanged();
    },
    "material.VariantCode": function () {
      this.matChanged();
    },
    "material.price": function () {
      this.priceChanged();
    },
    "material.quantity": function () {
      this.qtyChanged();
    },
    price: function () {
      this.material.price = this.price;
      this.priceChanged();
    },
    "material.price_total": function () {
      this.$emit("change");
    },
    "material.price_percent": function () {
      this.$emit("change");
    },
    "material.purchase": function () {
      let purchase = false;
      const selected = this.categories.filter(cat => cat.id == this.material.category_id)[0];
      if (selected.name === 'Einkaufsware' || this.material.purchase) {
        purchase = true;
      }
      this.$emit("purchase", purchase);
    },
    material(val) {
      // console.log(val)
    },
    categories() {
      if (!this.material.category_id) {
        this.$set(this.material, "category_id", this.categories[0].id);
      }
    },
  },
  methods: {
    async findVariants(query) {
      const response = await this.$http.post("variant/query", {
        and: {
          or: {
            "variant.Code": { like: "%" + query + "%" },
            "variant.InternalDescription": { like: "%" + query + "%" },
            "article.No": { like: "%" + query + "%" },
          },
          "article.active": true,
          "variant.active": true,
        },
        join: {
          article: { this: "ItemNo", operator: "=", that: "No" },
        },
        with: {
          article: { one: "article", this: "ItemNo", that: "No" },
        },
      });
      this.variants = response.body;
    },
    variantSelected(id) {
      const variant = this.variants.find((v) => v.id == id);
      this.material.mat_no = variant.ItemNo;
      this.material.VariantCode = variant.Code;
      this.material.variant = variant;
      this.material.article = variant.article;
      this.material.category_id = this.setStandardCategory(variant.ItemNo);
      if(!this.firstVariantChange){
        this.$emit("variantChanged");
        this.firstVariantChange = true;
      }
    },
    async matChanged() {
      this.updatePrices();
      /* No Navison price update (PICS-263)
      this.price = this.material.price = await this.getPrice();
      this.updatePrices();
      */
    },
    async getPrice() {
      // return 10.0;
      if (
        !this.project.customer_no ||
        !this.material.mat_no ||
        !this.material.VariantCode ||
        !this.material.quantity
      ) {
        return 0;
      }

      try {
        const result = await Vue.http.get("dyn/price", {
          params: {
            customerNo: this.project.customer_no,
            itemNo: this.material.mat_no,
            variantCode: this.material.VariantCode,
            quantity: this.material.quantity,
          },
        });
        return parseFloat(result.body.price);
      } catch (error) {
        const msg = error.body.message;
        this.$notify({
          verticalAlign: "top",
          horizontalAlign: "right",
          message: msg,
          type: "error",
          timeout: 0,
        });
        return null;
      }
    },
    categoryChanged(event) {
      let purchase = false;
      this.isInfoArticle = false;
      const selected = this.categories.filter(cat => cat.id == event)[0];
      if (selected.name === 'Einkaufsware' || this.material.purchase) {
        purchase = true;
      }
      if (selected.name === 'Infoartikel' || selected.name === 'Inhousearbeiten') {
        this.material.price_unit = null;
        this.material.price_percent = null;
        this.material.price_total = null;
        this.isInfoArticle = true;
      }
      this.$emit("purchase", purchase);
    },
    geoChanged() {
      if (this.material.width && this.material.height) {
        this.areaqm = (this.material.width * this.material.height) / 1000000.0;
        this.extent =
          (2 * this.material.width + 2 * this.material.height) / 1000.0;
      } else {
        this.areaqm = 0;
        this.extent = 0;
      }
      this.updatePrices();
    },
    setStandardCategory(variantNo) {
      const standardCategories = [
        {category_id: 5, category_name: 'Verbrauchsmaterial', type: 'KON', ItemNo: '0082'},
        {category_id: 981, category_name: 'Kundenware', type: 'MAT', ItemNo: '0107'},
        {category_id: 6166, category_name: 'Infoartikel', type: 'MAT', ItemNo: '0050'}
      ];

      const newId = standardCategories.filter(s => s.ItemNo == variantNo && s.type == this.material.type).map(s => s.category_id);
      return newId[0];
    },

    priceChanged() {
      this.updatePrices();
    },

    priceUnitChanged() {
      let price = this.material.price_unit;
      if (this.isPerQm) {
        price /= this.areaqm;
      }

      this.$set(this.material, "price", price);
    },
    // 'material.price_unit': function (val) {
    //   //this.$set(this.material, 'price', val)
    //   this.material.price_total = this.round(this.material.price_unit * this.material.quantity)
    //   this.$emit('change')
    // },
    // 'material.price': function (val) {
    //   // this.$set(this.material, 'price', val)
    //   this.$set(this.material, 'price_unit', this.getUnitPrice())
    //   this.$emit('change')
    // },

    qtyChanged() {
      this.updatePrices();
    },
    // https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
    round(value) {
      return Math.round(value * 100 + 0.00001) / 100;
    },
    calcWeight() {
      if (!this.material.variant) return 0;
      let weight = this.material.variant.weight;
      if (this.isPerQm) {
        weight *= this.areaqm;
      }
      return weight * this.material.quantity;
    },
    getUnitPrice() {
      let price = this.material.price;
      if (this.isPerQm) price *= this.areaqm;
      if (this.isPerExtent) price *= this.extent;
      return this.round(price);
    },
    updatePrices() {
      if (this.material.mat_no) {
        if (this.material.price) {
          this.$set(this.material, "price_unit", this.getUnitPrice());
        }
        this.material.price_total = this.round(
          this.material.price_unit * this.material.quantity
        );
      } else {
        this.material.price_unit = 0;
        this.material.price_total = 0;
      }
      this.material.weight = this.calcWeight();
      this.$emit("change");
    },
    pad(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
    reset() {
      // const last = this.item.materials.length>0 ? this.item.materials[this.item.materials.length-1] : null;
      // const first = this.item.materials.find(mat => mat.type==type);

      this.material.mat_no = null;
      this.material.VariantCode = null;
      this.material.width = 0;
      this.material.height = 0;
      this.material.purchase = 0;
      this.$emit("change");
    },
  },
  mounted() {
    if (this.material.width && this.material.height) {
      this.areaqm = (this.material.width * this.material.height) / 1000000.0;
      this.extent =
        (2 * this.material.width + 2 * this.material.height) / 1000.0;
    } else {
      this.areaqm = 0;
      this.extent = 0;
    }

    if (this.material.category.name === "Einkaufsware" && !this.material.purchase) {
      this.categoryChanged(this.material.category_id);
    }
    if (this.material.category.name === "Infoartikel" || this.material.category.name === "Inhousearbeiten") {
      this.isInfoArticle = true;
    }
  },
};
</script>
<style>
input[type="number"] {
  text-align: right;
}
</style>
