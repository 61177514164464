<template>
  <modal :show.sync="show" class="price-list-modal">
    <h6 slot="header" class="modal-title">{{ $t('message.pricelist') }}</h6>
    <div class="row">
      <div class="col-md-6">
          <el-table class="table-responsive table-flush clickable-rows"
                  header-row-class-name="thead-light"
                  :data="mats1">
            <el-table-column label="Material 1"
                              prop="fav"
                              width="100"
                              sortable>
                <template v-slot="{row}">
                  <file-icon :mime-type="row.mimetype"></file-icon>
                </template>
            </el-table-column>
            <el-table-column label="qm"
                              prop="name"
                              sortable>
            </el-table-column>
              <el-table-column label="Preis je qm"
                              prop="size"
                              sortable 
                              width="140">

            </el-table-column>
        </el-table>
      </div>
      <div class="col-md-6">
        <el-table class="table-responsive table-flush clickable-rows"
                header-row-class-name="thead-light"
                :data="mats2">
          <el-table-column label=""
                            prop="fav"
                            width="100"
                            sortable>
              <template v-slot="{row}">
                <file-icon :mime-type="row.mimetype"></file-icon>
              </template>
          </el-table-column>
          <el-table-column label="Dateiname"
                            prop="name"
                            sortable>
          </el-table-column>
            <el-table-column label="Größe"
                            prop="size"
                            sortable 
                            width="140">
          </el-table-column>
          <el-table-column align="right" width="90">
              <template v-slot="{row}">
                  <el-dropdown trigger="click" class="dropdown">
                  <span class="btn btn-sm btn-icon-only text-light">
                    <i class="fas fa-ellipsis-v mt-2"></i>
                  </span>
                      <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                          <a class="dropdown-item" href="#">Herunterladen</a>
                          <a class="dropdown-item" href="#">Kopieren</a>
                          <a class="dropdown-item" href="#">Löschen</a>
                      </el-dropdown-menu>
                  </el-dropdown>
              </template>
          </el-table-column>
      </el-table>
      </div>
    </div>
    <template slot="footer">
      <base-button type="primary" @click="onExportHandler">{{ $t('message.export') }}</base-button>
      <base-button type="link" class="ml-auto" @click="show = false">{{ $t('message.close') }}</base-button>
    </template>
  </modal>
</template>
<script>

  export default {
    name: 'DataInput',
    components: {

    },
    computed: {
      favoriteProjects () {
      }
    },
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        mats1:[],
        mats2:[],
      }
    },
    methods: {
      onExportHandler () {

      },
      copySelection () {

      },
      deleteSelection () {

      }
    },
    mounted() {
    
    }
  };
</script>
<style></style>
